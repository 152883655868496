import { defineMessages } from 'react-intl';

const messages = defineMessages({
    alertPasswordLength: {
        id: 'global.alertPasswordLength',
        defaultMessage: 'Password has to be at least 7 characters long'
    },
    alertPasswordTooMany: {
        id: 'global.alertPasswordTooMany',
        defaultMessage: 'You made too many password change requests, please try again later'
    },
    alertEmailInvalid: {
        id: 'global.alertEmailInvalid',
        defaultMessage: 'Please enter a valid email address'
    },
    alertNoUserFound: {
        id: 'global.alertNoUserFound',
        defaultMessage: 'No user found with email'
    },
    alertResetRequired: {
        id: 'global.alertSetRequired',
        defaultMessage: 'Password reset required. Please set your new password'
    },
    alertCorrectPassword: {
        id: 'global.alertCorrectPassword',
        defaultMessage: 'Invalid username or password.'
    },
    alertPasswordMatch: {
        id: 'global.alertPasswordMatch',
        defaultMessage: 'Passwords do not match'
    },
    alertPasswordUnknown: {
        id: 'global.alertPasswordUnknown',
        defaultMessage: 'Unable to set password. Please try again'
    },
    alertUserNotConfirmed: {
        id: 'global.alertUserNotConfirmed',
        defaultMessage: 'User account has not been confirmed yet. Please use Sign Up form again.'
    }
});

export default messages;
