import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Base/Icons/Icon';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import Headline from '../../Layout/Headline/Headline';
import Button from '../../Base/Button/Button';
import styles from './Login.module.scss';
import PasswordInput from './Parts/PasswordInput';
import SignInButton from './Parts/SignInButton';

// because input is in child component
/* eslint-disable jsx-a11y/label-has-associated-control */

const LoginForm = (props) => {
    const messages = defineMessages({
        emailPlaceholder: {
            id: 'global.forms.emailPlaceholder',
            defaultMessage: 'Your e-mail address'
        },
        passwordPlaceholder: {
            id: 'global.forms.passwordPlaceholder',
            defaultMessage: 'Your password'
        }
    });

    const handleFederatedSignIn = (provider) => {
        props.onFederatedSignIn({ provider });
    };

    function renderError() {
        if (!props.errorMessage || props.errorMessage.length === 0) return null;
        return <div className={styles.loginError}>{props.errorMessage}</div>;
    }

    function renderFields() {
        return (
            <label className={styles.inputLabel} htmlFor="password">
                <FormattedMessage defaultMessage="Password" id="global.forms.passwordLabel" />
                <div className={styles.inputIconWrapper}>
                    <PasswordInput
                        name="password"
                        placeholder={props.intl.formatMessage(messages.passwordPlaceholder)}
                        value={props.password}
                        onChange={props.onPasswordChange}
                        autoComplete="current-password"
                    />
                </div>
            </label>
        );
    }

    function renderButtons() {
        return (
            <>
                <div className={styles.loginButton}>
                    <Button
                        type="submit"
                        isLoading={props.isLoading}
                        design="confirm"
                        size="xlarge"
                        isFull
                    >
                        <FormattedMessage defaultMessage="Sign into your account" />
                    </Button>
                </div>

                <div className={styles.devider} />

                <div className={styles.federatedLoginButton}>
                    <SignInButton
                        onFederatedSignIn={handleFederatedSignIn}
                        provider="Google"
                        title={<FormattedMessage defaultMessage="Sign in with Google" />}
                        icon="Google"
                        design="default"
                    />
                </div>
            </>
        );
    }

    return (
        <form
            method="POST"
            onSubmit={props.onFormSubmit}
            className={`${styles.fullPageFormArea} ${styles.loginFormWrapper}`}
        >
            {props.isHeadline && (
                <Headline
                    title={<FormattedMessage defaultMessage="Sign in" />}
                    subtitle={
                        <FormattedMessage
                            defaultMessage="Welcome back! {newLine} Sign into your account"
                            values={{ newLine: <br /> }}
                        />
                    }
                />
            )}
            <div>
                <label className={styles.inputLabel} htmlFor="email">
                    <FormattedMessage
                        defaultMessage="E-mail address"
                        id="global.forms.emailLabel"
                    />
                    <div
                        className={`${styles.inputIconWrapper} ${
                            props.hasEmailError ? 'error' : ''
                        }`}
                    >
                        <div className={styles.inputIcon}>
                            <Icon name="Mail" size="sm" />
                        </div>
                        <input
                            type="email"
                            className={styles.inputLarge}
                            autoFocus
                            autoComplete="username"
                            placeholder={props.intl.formatMessage(messages.emailPlaceholder)}
                            onChange={props.onEmailChange}
                            value={props.email}
                            name="email"
                        />
                        <div className={styles.errorIcon}>
                            <Icon name="Error" size="sm" />
                        </div>
                    </div>
                </label>

                {renderFields()}
            </div>
            {renderButtons()}
            {renderError()}
        </form>
    );
};

export default injectIntl(LoginForm);

LoginForm.propTypes = {
    password: PropTypes.string,
    onPasswordChange: PropTypes.func,
    email: PropTypes.string,
    onEmailChange: PropTypes.func,
    onFormSubmit: PropTypes.func,
    hasEmailError: PropTypes.bool,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    isHeadline: PropTypes.bool,
    onFederatedSignIn: PropTypes.func
};
