import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Base/Icons/Icon';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import Headline from '../../Layout/Headline/Headline';
import Button from '../../Base/Button/Button';
import styles from './Login.module.scss';

const NewPasswordForm = (props) => {
    const messages = defineMessages({
        emailPlaceholder: {
            id: 'global.forms.emailPlaceholder',
            defaultMessage: 'Your e-mail address'
        },
        confirmPasswordPlaceholder: {
            id: 'global.forms.confirmPasswordPlaceholder',
            defaultMessage: 'Confirm new password'
        },
        newPasswordPlaceholder: {
            id: 'global.forms.newPasswordPlaceholder',
            defaultMessage: 'Your new Password'
        }
    });

    function renderError() {
        if (!props.errorMessage || props.errorMessage.length === 0) return null;
        return <div className={styles.loginError}>{props.errorMessage}</div>;
    }

    return (
        <form onSubmit={props.onFormSubmit} className={styles.fullPageFormArea}>
            <Headline
                title={<FormattedMessage defaultMessage="Almost there" />}
                subtitle={
                    <FormattedMessage
                        defaultMessage="Set your new password"
                        id="global.forms.newPasswordTitle"
                    />
                }
            />

            <label className={styles.inputLabel} htmlFor="newPassword">
                {props.intl.formatMessage(messages.newPasswordPlaceholder)}
                <div
                    className={`${styles.inputIconWrapper} ${
                        props.hasPasswordError ? 'error' : ''
                    }`}
                >
                    <input
                        type="password"
                        name="newPassword"
                        className={styles.inputLarge}
                        placeholder={props.intl.formatMessage(messages.newPasswordPlaceholder)}
                        autoFocus
                        value={props.password}
                        onChange={props.onPasswordChange}
                    />
                    <div className={styles.errorIcon}>
                        <Icon name="Error" size="sm" />
                    </div>
                </div>
            </label>
            <label className={styles.inputLabel} htmlFor="passwordRepeat">
                {props.intl.formatMessage(messages.confirmPasswordPlaceholder)}
                <div
                    className={`${styles.inputIconWrapper} ${
                        props.hasPasswordError ? 'error' : ''
                    }`}
                >
                    <input
                        type="password"
                        name="passwordRepeat"
                        className={styles.inputLarge}
                        placeholder={props.intl.formatMessage(messages.confirmPasswordPlaceholder)}
                        value={props.passwordRepeat}
                        onChange={props.onPasswordRepeatChange}
                    />
                    <div className={styles.errorIcon}>
                        <Icon name="Error" size="sm" />
                    </div>
                </div>
            </label>
            <Button
                type="submit"
                design="confirm"
                size="xlarge"
                isFull
                isLoading={props.isLoading}
            >
                <FormattedMessage defaultMessage="Continue and log in" />
            </Button>
            {renderError()}
        </form>
    );
};

export default injectIntl(NewPasswordForm);

NewPasswordForm.propTypes = {
    password: PropTypes.string,
    onPasswordChange: PropTypes.func,
    passwordRepeat: PropTypes.string,
    onPasswordRepeatChange: PropTypes.func,
    onFormSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    hasPasswordError: PropTypes.bool,
    isLoading: PropTypes.bool
};
