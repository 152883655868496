import PropTypes from 'prop-types';
import BasicLoginForm from '../../../components/User/LoginComponents/LoginForm';
import validator from 'email-validator';
import { trackEvent } from '../../../utils/trackingParty';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './LoginMessages';
import React, { useReducer, useEffect } from 'react';
import { signIn, setNewPassword, federatedSignIn } from '../../../actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import NewPasswordForm from '../../../components/User/LoginComponents/NewPasswordForm';

const Login = (props) => {
    const intl = useIntl();
    const initialState = {
        isLoading: false,
        email: '',
        emailError: false,
        errorMessage: null,
        password: '',
        newPassword: '',
        newPasswordRepeat: '',
        passwordError: false,
        isSubmitted: false
    };

    const [state, setState] = useReducer(
        (localState, newState) => ({ ...localState, ...newState }),
        initialState
    );

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
        // prefill email
        if (props.prefillEmail) {
            setState({ email: props.prefillEmail });
        }
    }, [props.prefillEmail]);

    useEffect(() => {
        if (!state.isSubmitted) return;

        if (auth.authenticated === true) {
            if (props.onLoginSuccess) {
                props.onLoginSuccess(auth.user);
            }
        } else if (auth.changePassword === true) {
            setState({
                isLoading: false,
                errorMessage: intl.formatMessage(messages.alertResetRequired)
            });
        } else {
            const { error } = auth;
            trackEvent('Login error', {
                code: error.code,
                message: error.message
            });

            if (error.name === 'UserNotFoundException') {
                setState({
                    isLoading: false,
                    errorMessage: intl.formatMessage(messages.alertNoUserFound)
                });
            } else {
                setState({
                    isLoading: false,
                    errorMessage: intl.formatMessage(messages.alertCorrectPassword)
                });
            }
        }
    }, [auth]);

    const handleLoginSubmit = () => {
        setState({ isSubmitted: true });
    };

    const submitLoginForm = async (e) => {
        e.preventDefault();
        setState({ isLoading: true, emailError: false });

        if (state.email.length === 0 || !validator.validate(state.email)) {
            setState({
                isLoading: false,
                emailError: true,
                errorMessage: intl.formatMessage(messages.alertEmailInvalid)
            });
            return false;
        }

        dispatch(signIn(state.email.toLowerCase(), state.password));
        // console.log('SUBMIT LOGIN');
        handleLoginSubmit();

        return false;
    };

    const handleEmailChange = (e) => {
        e.preventDefault();
        setState({ email: e.target.value });
    };

    const handlePasswordChange = (e) => {
        e.preventDefault();
        setState({ password: e.target.value });
    };

    const handleNewPasswordChange = (e) => {
        e.preventDefault();
        setState({ newPassword: e.target.value });
    };

    const handleNewPasswordRepeatChange = (e) => {
        e.preventDefault();
        setState({ newPasswordRepeat: e.target.value });
    };

    const submitNewPasswordForm = (e) => {
        e.preventDefault();

        if (state.newPassword.length < 7) {
            setState({
                passwordError: true,
                errorMessage: props.intl.formatMessage(messages.alertPasswordLength)
            });
            return false;
        }
        if (state.newPassword !== state.newPasswordRepeat) {
            setState({
                passwordError: true,
                errorMessage: props.intl.formatMessage(messages.alertPasswordMatch)
            });
            return false;
        }

        setState({ loading: true, passwordError: false });
        dispatch(setNewPassword(auth.cognitoUser, state.newPassword, handleLoginSuccess));

        // cancel page refresh
        return false;
    };

    const handleLoginSuccess = (user) => {
        setState({ loading: false });
        props.onLoginSuccess(user);
    };

    const handleFederatedSignIn = async ({ provider }) => {
        await federatedSignIn({ provider });
    };

    function renderSuccess() {
        if (props.query && props.query.open === 'password-success') {
            return (
                <span className="color-green">
                    <FormattedMessage defaultMessage="Your password has been changed successfuly!" />
                </span>
            );
        }
        return null;
    }

    function renderForm() {
        if (auth.changePassword) {
            return (
                <NewPasswordForm
                    password={state.newPassword}
                    onPasswordChange={handleNewPasswordChange}
                    onFormSubmit={submitNewPasswordForm}
                    onPasswordRepeatChange={handleNewPasswordRepeatChange}
                    passwordRepeat={state.newPasswordRepeat}
                    hasPasswordError={state.passwordError}
                    errorMessage={state.errorMessage}
                    isLoading={state.isLoading}
                />
            );
        }

        return (
            <BasicLoginForm
                password={state.password}
                onPasswordChange={handlePasswordChange}
                onEmailChange={handleEmailChange}
                email={state.email}
                onFormSubmit={submitLoginForm}
                onFederatedSignIn={handleFederatedSignIn}
                errorMessage={state.errorMessage}
                hasEmailError={state.emailError}
                isLoading={state.isLoading}
                isHeadline={props.isHeadline}
            />
        );
    }

    return (
        <>
            {renderForm()}
            {renderSuccess()}
        </>
    );
};

export default Login;

Login.propTypes = {
    onLoginSuccess: PropTypes.func,
    prefillEmail: PropTypes.string,
    isHeadline: PropTypes.bool
};
