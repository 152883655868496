import React from 'react';
import Button from '../../../Base/Button/Button';
import Icon from '../../../Base/Icons/Icon';
import styles from '../Login.module.scss';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const SignInButton = (props) => {
    const handleConfirm = () => {
        props.onFederatedSignIn(props.provider);
    };

    const renderTitle = () => {
        return (
            props.title ?? (
                <FormattedMessage
                    defaultMessage="Sign in with {provider}"
                    values={{ provider: props.provider }}
                />
            )
        );
    };

    return (
        <Button
            design={props.design || 'confirm'}
            size="xlarge"
            isFull
            isLoading={props.isLoading}
            onClick={handleConfirm}
        >
            <span className={styles.iconRight}>
                <Icon name={props.provider} size="sm" />
            </span>
            {renderTitle()}
        </Button>
    );
};

export default SignInButton;

SignInButton.propTypes = {
    provider: PropTypes.string.isRequired,
    design: PropTypes.string,
    isLoading: PropTypes.bool,
    onFederatedSignIn: PropTypes.func
};
